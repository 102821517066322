import React, { useContext } from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { Nav, Navbar } from 'react-bootstrap'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Image from '../generic/Image'
import NaviColumn from '../artboxone/navi/Column'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import FavoriteIcon from '@mui/icons-material/Favorite'
import { Badge } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import SearchField from '../products/Searchfield'
import Tracking from './Tracking'
import HeaderBar from './HeaderBar'
import BusinessIcon from '@mui/icons-material/Business'
import { getOpenOrderCount } from '../../lib/orders_hook'
import Caching from './Caching'
import SearchContext from '../../context/SearchContext'
import { NavigationNode } from '../../types/cockpit_cms/navigation'
import PageInformationContext from '../../context/PageInformationContext'

export interface HeaderOptions {
  siteTitle: string
  menuLinks: NavigationNode[]
  language: string
  location: Location
}

const Header = (options: HeaderOptions) => {
  const [fixedNavbar, setFixedNavbar] = React.useState('top')
  const { pageInformation } = useContext(PageInformationContext)

  const { favoritesCount, cartCount } = useContext(SearchContext)

  const orderCount = getOpenOrderCount()

  const searchIcon = <SearchIcon />

  // Search
  const [dropDownOpen, setDropDownOpen] = React.useState(false)

  // Rest
  const [openDropdown, setOpenDropdown] = React.useState('')

  const [mouseDownInsideDropdown, setMouseDownInsideDropdown] =
    React.useState(false)

  // Navi expanded mobile
  const [expanded, setExpanded] = React.useState(false)

  const SearchNavItem = (
    <Nav.Item>
      <NavDropdown
        className="position-static artboxone-search"
        focusFirstItemOnShow={true}
        onClick={() => {
          if (mouseDownInsideDropdown) {
            setOpenDropdown('')
            setDropDownOpen(!dropDownOpen)
          }

          setMouseDownInsideDropdown(false)
        }}
        onMouseDown={() => {
          setMouseDownInsideDropdown(true)
        }}
        show={dropDownOpen}
        title={searchIcon}
      >
        <div className="d-flex justify-content-center">
          <SearchField
            pageInformation={pageInformation}
            showSubmitButton={true}
          />
        </div>
      </NavDropdown>
    </Nav.Item>
  )

  // Disabling fixed topbar to access dropdowns on mobile by scrolling down
  // Topbar only fixed when dropdown is closed
  const onToggleNavbar = (e: boolean) => {
    if (e) {
      setExpanded(true)
      window.scrollTo(0, 0)
      setFixedNavbar(undefined)
    } else {
      setExpanded(false)
      setFixedNavbar('top')
    }
  }

  const handleDropdown = (ev: any) => {
    ev.preventDefault()
    setDropDownOpen(false)

    if (openDropdown == ev.currentTarget.dataset.id) {
      setOpenDropdown('')
    } else {
      setOpenDropdown(ev.currentTarget.dataset.id)
    }
  }

  // {/* <Nav.Item>
  //     <Link to="/user/" className="nav-link">
  //       <PersonIcon />
  //       <span className="d-md-none">Mein Konto</span>
  //     </Link>
  //   </Nav.Item> */}

  //

  return (
    <header
      style={{
        background: `#eee`,
        marginBottom: `1.45rem`,
      }}
    >
      <HeaderBar />
      <Navbar
        bg="light"
        className="py-0 nav-with-headerbar"
        expand="lg"
        expanded={expanded}
        fixed={fixedNavbar}
        onToggle={onToggleNavbar}
      >
        <div className="container">
          <Navbar.Brand as={Link} to="/">
            <div className="d-none d-md-block">
              <Image filename="logo_small.png" />
            </div>
            <div className="d-block d-sm-none small_logo">
              <Image filename="logo_mobile2.png" />
            </div>
            <div className="d-none d-sm-block d-md-none">
              <Image filename="logo_mobile.png" />
            </div>
          </Navbar.Brand>

          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              {options.menuLinks
                .filter(function (link: NavigationNode) {
                  const result =
                    link.published.value === true &&
                    link.lang === options.language &&
                    link.link.value !== '-'

                  return result
                })
                .map((link: any) => (
                  <div
                    className="dropdown nav-item"
                    key={'outerDivDropdown' + link.id}
                  >
                    <a
                      aria-expanded="true"
                      aria-haspopup="true"
                      className="dropdown-toggle nav-link"
                      data-id={link.id}
                      href={link.link.value}
                      onClick={handleDropdown}
                      role="button"
                    >
                      {link.name.value}
                    </a>
                    <div
                      className={
                        openDropdown == link.id
                          ? 'dropdown-menu show'
                          : 'dropdown-menu'
                      }
                      onClick={() => {
                        setOpenDropdown('')
                      }}
                    >
                      <div className="container container-sm">
                        <div className="row">
                          <NaviColumn
                            fields={link.felder_1}
                            position={link.name.value + ',' + 1}
                            setExpanded={setExpanded}
                            title={link.titel_1}
                          />
                          <NaviColumn
                            fields={link.felder_2}
                            position={link.name.value + ',' + 2}
                            setExpanded={setExpanded}
                            title={link.titel_2}
                          />
                          <NaviColumn
                            fields={link.felder_3}
                            position={link.name.value + ',' + 3}
                            setExpanded={setExpanded}
                            title={link.titel_3}
                          />
                          <NaviColumn
                            fields={link.felder_4}
                            position={link.name.value + ',' + 4}
                            setExpanded={setExpanded}
                            title={link.titel_4}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </Nav>
          </Navbar.Collapse>

          {SearchNavItem}
          {orderCount > 0 && (
            <Link className="nav-link" to="/orders/">
              <Badge badgeContent={orderCount} color="primary">
                <BusinessIcon />
              </Badge>
            </Link>
          )}
          <Link className="nav-link" to="/favoriten">
            <Badge badgeContent={favoritesCount} color="primary">
              <FavoriteIcon />
            </Badge>
          </Link>
          <Link className="nav-link" to="/warenkorb">
            <Badge badgeContent={cartCount} color="primary">
              <ShoppingCartIcon />
            </Badge>
          </Link>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
        </div>
      </Navbar>
      <Tracking location={options.location} />
      <Caching />
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
