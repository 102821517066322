exports.components = {
  "component---src-lp-alternatives-tsx": () => import("./../../../src/lp/alternatives.tsx" /* webpackChunkName: "component---src-lp-alternatives-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-routing-order-push-tsx": () => import("./../../../src/routing/OrderPush.tsx" /* webpackChunkName: "component---src-routing-order-push-tsx" */),
  "component---src-routing-product-page-tsx": () => import("./../../../src/routing/ProductPage.tsx" /* webpackChunkName: "component---src-routing-product-page-tsx" */),
  "component---src-routing-products-page-routing-magento-tsx": () => import("./../../../src/routing/ProductsPageRoutingMagento.tsx" /* webpackChunkName: "component---src-routing-products-page-routing-magento-tsx" */),
  "component---src-templates-cart-overview-tsx": () => import("./../../../src/templates/cart/overview.tsx" /* webpackChunkName: "component---src-templates-cart-overview-tsx" */),
  "component---src-templates-checkout-checkout-tsx": () => import("./../../../src/templates/checkout/Checkout.tsx" /* webpackChunkName: "component---src-templates-checkout-checkout-tsx" */),
  "component---src-templates-debug-tsx": () => import("./../../../src/templates/debug.tsx" /* webpackChunkName: "component---src-templates-debug-tsx" */),
  "component---src-templates-delete-tsx": () => import("./../../../src/templates/delete.tsx" /* webpackChunkName: "component---src-templates-delete-tsx" */),
  "component---src-templates-favorites-import-tsx": () => import("./../../../src/templates/favorites_import.tsx" /* webpackChunkName: "component---src-templates-favorites-import-tsx" */),
  "component---src-templates-favorites-tsx": () => import("./../../../src/templates/favorites.tsx" /* webpackChunkName: "component---src-templates-favorites-tsx" */),
  "component---src-templates-landingpage-tsx": () => import("./../../../src/templates/landingpage.tsx" /* webpackChunkName: "component---src-templates-landingpage-tsx" */),
  "component---src-templates-orders-tsx": () => import("./../../../src/templates/orders.tsx" /* webpackChunkName: "component---src-templates-orders-tsx" */),
  "component---src-templates-sitemap-tsx": () => import("./../../../src/templates/sitemap.tsx" /* webpackChunkName: "component---src-templates-sitemap-tsx" */),
  "component---src-templates-upselling-index-tsx": () => import("./../../../src/templates/upselling/index.tsx" /* webpackChunkName: "component---src-templates-upselling-index-tsx" */)
}

