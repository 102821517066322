const DEFAULT_LOCALE = 'en-GB'
const LOCALE = process.env.GATSBY_LOCALE || DEFAULT_LOCALE
const LANGUAGE = LOCALE.substr(0, 2)
const COUNTRY = LOCALE.substr(3, 2)
const BASE_DOMAIN = 'artboxone.co.uk'
const BASE_URL = 'https://www.artboxone.co.uk'
const SEO = 1
const PIXUM_URL = 'https://www.pixum.co.uk'
const ACTIVATE_KAMELEOON = 1
const ACTIVATE_PLAUSIBLE = 0
const SERVICE_HOST = 'https://si.artbox.one/'
const PIXUM_PSI = 'https://psi.pixum.com/'
const CMS_URL = 'https://cms.artbox.one'

exports.DEFAULT_LOCALE = DEFAULT_LOCALE
exports.LOCALE = LOCALE
exports.LANGUAGE = LANGUAGE
exports.COUNTRY = COUNTRY
exports.BASE_URL = BASE_URL
exports.BASE_DOMAIN = BASE_DOMAIN
exports.SEO = SEO
exports.PIXUM_URL = PIXUM_URL
exports.ACTIVATE_KAMELEOON = ACTIVATE_KAMELEOON
exports.ACTIVATE_PLAUSIBLE = ACTIVATE_PLAUSIBLE
exports.SERVICE_HOST = SERVICE_HOST
exports.PIXUM_PSI = PIXUM_PSI
exports.CMS_URL = CMS_URL
